import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Suspense } from "react";
import {
  startConnection,
  stopConnection,
  invoke,
} from "../components/signalRService";
import Header from "../shared/Header";
import SettingsHeader from "../shared/SettingsHeader";
import SettingsSideBar from "../shared/SettingsSideBar";
import SuperAdminHeader from "../superAdmin/component/SuperAdminHeader";
import SuperAdminSidebar from "../superAdmin/component/SuperAdminSidebar";
import AdministrativeSettingSidebar from "../shared/AdministrativeSettingSidebar";

export const AuthorizedInner = ({
  cmp: Component,
  headerType = "CommonLayout",
  ...rest
}) => {
  const [isAuthorized, setIsAuthorized] = useState(false);
  const [isSignUpCompleted, setIsSignUpCompleted] = useState(false);
  const [clientId, setClientId] = useState(localStorage.getItem("ClientID"));
  const [userId, setUserId] = useState(localStorage.getItem("UserID"));

  const navigate = useNavigate();

  const checkAuthorization = () => {
    return localStorage.getItem("AccessToken") ? true : false;
  };

  const checkSuperAdminAuthorization = () => {
    return localStorage.getItem("SuperAdminToken") ? true : false;
  };

  useEffect(() => {
    if (headerType === "SuperAdminLayout") {
      const isAuthorize = checkSuperAdminAuthorization();
      setIsAuthorized(isAuthorize);
      if (!isAuthorize) {
        navigate("/superadmin/login");
      }
    } else {
      const isAuthorize = checkAuthorization();
      setIsAuthorized(isAuthorize);
      if (!isAuthorize) {
        navigate("/login");
      } else {
        const foundProfile = JSON.parse(
          localStorage.getItem("Profile") ?? "{}"
        );
        if (
          foundProfile.companyCreated === "No" &&
          foundProfile?.profileCompleted == "No"
        ) {
          navigate("/profileadmin");
          setIsSignUpCompleted(false);
        } else if (
          foundProfile?.profileCompleted == "No" &&
          foundProfile?.companyCreated == "Yes"
        ) {
          navigate("/profilemember");
          setIsSignUpCompleted(false);
        } else if (
          Number(localStorage.getItem("PORTALS_LENGTH")) > 1 &&
          !localStorage.getItem("selectedPortalIndex")
        ) {
          navigate("/portals");
        } else {
          setIsSignUpCompleted(true);
        }
      }
    }
  }, []);

  useEffect(() => {
    if (headerType != "SuperAdminLayout") {
      if (!clientId && !userId && !isSignUpCompleted) return;

      setTimeout(() => {
        startConnection(clientId?.toString(), userId?.toString());
      }, 2000);

      return () => {
        stopConnection();
      };
    }
  }, [clientId, userId]);

  return (
    isAuthorized && (
      <>
        {headerType === "CommonLayout" && (
          <>
            <Header />
            <div
              style={{
                background: "#FAFBFC",
                minHeight: "100vh",
                display: "grid",
              }}
            >
              <div className="main-content">
                <Suspense fallback={<div></div>}>
                  <Component {...rest} />
                </Suspense>
              </div>
            </div>
          </>
        )}
        {headerType === "SettingsLayout" && (
          <div className="setting-module">
            <SettingsHeader />
            <div className="setting-flex">
              <SettingsSideBar />
              <Suspense fallback={<div></div>}>
                <Component {...rest} />
              </Suspense>
            </div>
          </div>
        )}
        {headerType === "AdministrativeSettingsLayout" && (
          <div className="setting-module">
            <SettingsHeader />
            <div className="setting-flex">
              <AdministrativeSettingSidebar />
              <Suspense fallback={<div></div>}>
                <Component {...rest} />
              </Suspense>
            </div>
          </div>
        )}
        {headerType === "SuperAdminLayout" && (
          <div className="setting-module">
            <SuperAdminHeader />
            <div className="setting-flex">
              <SuperAdminSidebar />
              <Suspense fallback={<div></div>}>
                <Component {...rest} />
              </Suspense>
            </div>
          </div>
        )}
      </>
    )
  );
};
