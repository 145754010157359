import { toast } from 'react-toastify';

class ServiceOnboarding {
    constructor() {
        this.IdentityToken = localStorage.getItem('Identitytoken');
        this.ApiBaseURL_login = process.env.REACT_APP_LOGIN_URL;
        this.ApiBaseURL_Reg = process.env.REACT_APP_REGISTER_URL;
        this.ApibaseURL = process.env.REACT_APP_URL;
        this.AccessToken = localStorage.getItem('AccessToken');
        this.Email = localStorage.getItem('Email');
        this.ClientID = Number(localStorage.getItem('ClientID'));
        this.ip_address = localStorage.getItem("IP_ADDRESS");
        this.items = [];
    }

    async retrieveItems() {
        return Promise.resolve(this.items);
    }

    async Login(req) {
        try {
            const dataset = {
                method: "POST",
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    "Email": req.Email,
                    "Password": req.Password,
                    "IdentityToken": this.IdentityToken,
                    "Device": "Web",
                    "IPAddress": this.ip_address
                })
            };
            const response = await fetch(`${this.ApiBaseURL_login}login`, dataset);
            if (response.status === 401) {
                toast.error('Your Session has expired, Please login again.');
                setTimeout(() => {
                    localStorage.clear();
                    window.location.href = "/#/login";
                }, 1000);
                return;
            }
            const result = await response.json();
            if (response.ok) {
                // localStorage.setItem('AccessToken', result.accessToken);
                this.items = result;
                return result;
            } else if (response.status === 400) {
                toast.error(result.errors[0]);
            }
        } catch (error) {
            toast.error('Something went wrong, Please try again later.');
        }
    }

    async UserProfile(req) {
        try {
            const dataset = {
                method: "GET",
                headers: {
                    'Authorization': 'Bearer ' + localStorage.getItem('AccessToken')
                }
            };

            const response = await fetch(`${this.ApibaseURL}users/profile?email=${req.Email}`, dataset);
            if (response.status === 401) {
                toast.error('Your Session has expired, Please login again.');
                setTimeout(() => {
                    localStorage.clear();
                    window.location.href = "/#/login";
                }, 1000);
                return;
            }
            const result = await response.json();
            if (response.ok) {
                localStorage.setItem('FullName', result.fullName);
                localStorage.setItem('Email', result.email);
                localStorage.setItem('UserID', result.userId);
                localStorage.setItem('Profile', JSON.stringify(result));
                
                this.items = result;
                return result;
            } else if (response.status === 400) {
                toast.error(result.errors[0]);
            }
        } catch (error) {
            toast.error('Something went wrong, Please try again later.');
        }
    }

    async getNotification_api(req) {
        try {
            const dataset = {
                method: "GET",
                headers: {
                    'Authorization': 'Bearer ' + localStorage.getItem('AccessToken')
                }
            };
            const response = await fetch(`${this.ApibaseURL}notification/list?email=${req.Email}&clientid=${req.ClientID}&filter=All`, dataset);
            if (response.status === 401) {
                toast.error('Your Session has expired, Please login again.');
                setTimeout(() => {
                    localStorage.clear();
                    window.location.href = "/#/login";
                }, 1000);
                return;
            }
            const result = await response.json();
            if (response.ok) {
                return result;
            } else if (response.status === 400) {
                toast.error(result.errors);
            }
        } catch (error) {
            toast.error('Something went wrong, Please try again later.');
        }
    }

    async markasread_api(req) {
        try {
            const dataset = {
                method: "GET",
                headers: {
                    'Authorization': 'Bearer ' + localStorage.getItem('AccessToken')
                }
            };
            const response = await fetch(`${this.ApibaseURL}notification/markread?email=${req.Email}&clientid=${req.ClientID}&record=${req.record}&id=${req.id}`, dataset);
            if (response.status === 401) {
                toast.error('Your Session has expired, Please login again.');
                setTimeout(() => {
                    localStorage.clear();
                    window.location.href = "/login";
                }, 1000);
                return;
            }
            const result = await response.json();
            if (response.ok) {
                return result;
            } else if (response.status === 400) {
                toast.error(result.errors);
            }
        } catch (error) {
            toast.error('Something went wrong, Please try again later.');
        }
    }

    async RegisterUser(req) {
        try {
            const dataset = {
                method: "POST",
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    "FullName": req.Name,
                    "Email": req.Email,
                    "Password": req.Password,
                    "SignupMode": "Web",
                    "IdentityToken": this.IdentityToken
                })
            };
            const response = await fetch(`${this.ApiBaseURL_Reg}register`, dataset);
            return response;        
           
        } catch (error) {
            toast.error('Something went wrong, Please try again later.');
        }
    }

    async UploadProfile(req) {
        try {
            const Request = {
                method: 'POST',
                headers: {
                    'Authorization': 'Bearer ' + localStorage.getItem('AccessToken')
                },
                body: req.formData
            };
            const response = await fetch(`${this.ApibaseURL}uploadprofileimage`, Request);
            if (response.status === 401) {
                toast.error('Your Session has expired, Please login again.');
                setTimeout(() => {
                    localStorage.clear();
                    window.location.href = "/#/login";
                }, 1000);
                return;
            }
            const result = await response.json();
            if (response.ok) {
                this.items = result;
                return result;
            } else if (response.status === 400) {
                toast.error(result.errors[0]);
            }
        } catch (error) {
            toast.error('Something went wrong, Please try again later.');
        }
    }

    async AddCompanyInfo(req) {
        try {
            const Request = {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + localStorage.getItem('AccessToken')
                },
                body: JSON.stringify({
                    "Email": req.Email,
                    "CompanyName": req.CompanyName,
                    "JobTitle": req.JobTitle,
                    "Phone": req.Phone,
                    "CountryCode": req.CountryCode,
                    "STDCode": req.STDCode,
                    "TimeZoneID": req.TimeZoneID,
                    "ProfileImageURL": req.ProfileImageURL
                })
            };
            const response = await fetch(`${this.ApibaseURL}onboarding/companyinfo`, Request);
            return response;
        } catch (error) {
            toast.error('Something went wrong, Please try again later.');
        }
    }

    async AddMemberInfo(req) {
        try {
            const Request = {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + localStorage.getItem('AccessToken')
                },
                body: JSON.stringify({
                    "Email": req.Email,
                    "CompanyName": req.CompanyName,
                    "JobTitle": req.JobTitle,
                    "Phone": req.Phone,
                    "CountryCode": req.CountryCode,
                    "STDCode": req.STDCode,
                    "TimeZoneID": req.TimeZoneID,
                    "ProfileImageURL": req.ProfileImageURL
                })
            };
            const response = await fetch(`${this.ApibaseURL}onboarding/memberinfo`, Request);
            if (response.status === 401) {
                toast.error('Your Session has expired, Please login again.');
                setTimeout(() => {
                    localStorage.clear();
                    window.location.href = "/#/login";
                }, 1000);
                return;
            }
            const result = await response.json();
            if (response.ok) {
                this.items = result;
                return result;
            } else if (response.status === 400) {
                toast(result.errors[0]);
            }
        } catch (error) {
            toast.error('Something went wrong, Please try again later.');
        }
    }

    async AddOfficeTeams(req) {
        try {
            const Request = {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + localStorage.getItem('AccessToken')
                },
                body: JSON.stringify({
                    "Email": req.Email,
                    "ClientID": req.ClientID,
                    "OfficeTeams": req.officeteams
                })
            };
            const response = await fetch(`${this.ApibaseURL}onboarding/officeteams`, Request);
            return response;
        } catch (error) {
            toast.error('Something went wrong, Please try again later.');
        }
    }

    async AddProjects(req) {
        try {
            const Request = {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + localStorage.getItem('AccessToken')
                },
                body: JSON.stringify({
                    "Email": req.Email,
                    "ClientID": req.ClientID,
                    "Projects": req.projects
                })
            };
            const response = await fetch(`${this.ApibaseURL}onboarding/projects`, Request);
            if (response.status === 401) {
                toast.error('Your Session has expired, Please login again.');
                setTimeout(() => {
                    localStorage.clear();
                    window.location.href = "/#/login";
                }, 1000);
                return;
            }
            const result = await response.json();
            if (response.ok) {
                this.items = result;
                return result;
            } else if (response.status === 400) {
                toast(result.errors[0]);
            }
        } catch (error) {
            toast.error('Something went wrong, Please try again later.');
        }
    }

    async FinishOnboarding(Email) {
        try {
            const request = {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + localStorage.getItem('AccessToken')
                },
                body: JSON.stringify({
                    "Email": Email,
                    "ActivePlan": {
                        "plan_code": "FREETRIAL"
                    }
                })
            };
            const response = await fetch(`${this.ApibaseURL}onboarding/finish`, request);
            return response;
        } catch (error) {
            toast.error('Something went wrong, Please try again later.');
        }
    }

    async Masterlist() {       
        const dataset = {
            method: "GET",
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('AccessToken')
            }
        };
        const response = await fetch(`${this.ApibaseURL}masterlist`, dataset);
        return response;
    }

    async ResetPasswordEmail(Email) {
        try {
            const request = {
                method: "POST",
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    "Email": Email,
                    "IdentityToken": this.IdentityToken,
                    "Device": "Web",
                    "IPAddress": this.ip_address
                })
            };
            const response = await fetch(`${this.ApibaseURL}users/resetpassword?action=getotp`, request);
            if (response.status === 401) {
                toast.error('Your Session has expired, Please login again.');
                setTimeout(() => {
                    localStorage.clear();
                    window.location.href = "/#/login";
                }, 1000);
                return;
            }
            const result = await response.json();
            if (response.ok) {
                this.items = result;
                return result;
            } else if (response.status === 400) {
                toast.error(result.errors[0]);
                return result;
            }
        } catch (error) {
            toast.error('Something went wrong, Please try again later.');
        }
    }

    async ResetPasswordValidateOTP(req) {
        try {
            const request = {
                method: "POST",
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    "Email": req.Email,
                    "OTP": req.otp,
                    "IdentityToken": this.IdentityToken,
                    "Device": "Web",
                    "IPAddress": this.ip_address
                })
            };
            const response = await fetch(`${this.ApibaseURL}users/resetpassword?action=validateotp`, request);
            if (response.status === 401) {
                toast.error('Your Session has expired, Please login again.');
                setTimeout(() => {
                    localStorage.clear();
                    window.location.href = "/#/login";
                }, 1000);
                return;
            }
            const result = await response.json();
            if (response.ok) {
                this.items = result;
                return result;
            } else if (response.status === 400) {
                toast.error(result.errors[0]);
                 
            }
        } catch (error) {
            toast.error('Something went wrong, Please try again later.');
        }
    }

    async ResetPassword(req) {
        try {
            const request = {
                method: "POST",
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    "Email": req.Email,
                    "NewPassword": req.Password,
                    "IdentityToken": this.IdentityToken,
                    "Device": "Web",
                    "IPAddress": this.ip_address
                })
            };
            const response = await fetch(`${this.ApibaseURL}users/resetpassword?action=changepassword`, request);
            if (response.status === 401) {
                toast.error('Your Session has expired, Please login again.');
                setTimeout(() => {
                    localStorage.clear();
                    window.location.href = "/#/login";
                }, 1000);
                return;
            }
            const result = await response.json();
            if (response.ok) {
                this.items = result;
                return result;
            } else if (response.status === 400) {
                toast.error(result.errors[0]);
            }
        } catch (error) {
            toast.error('Something went wrong, Please try again later.');
        }
    }

    async ChangePassword(req) {
        try {
            const request = {
                method: "POST",
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + localStorage.getItem('AccessToken')
                },
                body: JSON.stringify({
                    "Email": req.Email,
                    "NewPassword": req.NewPassword,
                    "OldPassword": req.OldPassword,
                    "IdentityToken": this.IdentityToken,
                    "Device": "Web",
                    "IPAddress": this.ip_address
                })
            };
            const response = await fetch(`${this.ApibaseURL}settings/changepassword`, request);
            if (response.status === 401) {
                toast.error('Your Session has expired, Please login again.');
                setTimeout(() => {
                    localStorage.clear();
                    window.location.href = "/#/login";
                }, 1000);
                return;
            }
            const result = await response.json();
            if (response.ok) {
                this.items = result;
                return result;
            } else if (response.status === 400) {
                toast.error(result.errors[0]);
            }
        } catch (error) {
            toast.error('Something went wrong, Please try again later.');
        }
    }
}

export default ServiceOnboarding;
