import React, { useEffect } from 'react'
import ContactUsSection from './components/ContactUsSection'
import Footer from './components/Footer'
import Header from './components/Header'
import NewFooter from './components/newFooter'
import { Link, useNavigate, useLocation } from "react-router-dom";

const AboutUs = () => {
    useEffect(() => {
        window.scrollTo(0, 0)
        localStorage.setItem("scrollTo", "")
    }, [])
    return (
        <div className="publicpage">

            <Header />
            <div className='about-page'>


                {/* Top Banner */}
                <section className="about-top-section">

                    <div className="row">
                        <div className=''>
                            <img src="../images/about-us/about-bg-logo.svg" alt="" className="about-top-right-logo" />
                        </div>
                        <div className="col-md-12 col-sm-12 ">
                            <div className="top-banner-text">
                                <h1>We're on a Mission to <br />Transform Collaboration <br /> and
                                    Productivity through<br /> Innovative <span className=''>AI-driven <br />Solutions</span></h1>

                            </div>
                        </div>
                    </div>


                </section>
                <section class="vision-section">
                    <div class="row">
                        <div class="col-md-6">
                            <img src="../images/about-us/our-vision-1.png" class="img-fluid img1" alt="" />

                            <div className='vision-box'>
                                <h4> Our Vision </h4>
                                <p>At Synthium, we believe in the power of technology to simplify complex workflows
                                    and empower teams to achieve their goals. Our vision is to revolutionize the future
                                    of work by providing intuitive tools that foster creativity, enhance productivity,
                                    and promote seamless collaboration across teams and organizations.</p>
                            </div>

                        </div>
                        <div class="col-md-6">
                            <img src="../images/about-us/our-vision-2.png" class="img-fluid img2" alt="" />
                        </div>
                    </div>

                </section>

                {/* <!-- Innovation Section --> */}
                <section className="innovation-section">

                    <div className="row d-flex flex-wrap align-items-stretch">
                        <div className="col-md-6 col-sm-12">
                            <h2 className="mb-3">
                                Innovation<br /> at the core
                            </h2>
                            <img src="../images/about-us/innovation-core1.png" className="img-fluid img1" alt="" />
                        </div>


                        <div className="col-md-6 col-sm-12 ">

                            <img src="../images/about-us/innovation-core2.png" className="img-fluid img2" alt="" />
                            <p className='para'>Founded by a team of passionate technology enthusiasts, Synthium is dedicated to pushing
                                the boundaries of what's possible with artificial intelligence. We harness cutting-edge AI
                                technology to develop intelligent software solutions that adapt to the unique needs and challenges
                                faced by modern businesses.</p>
                        </div>
                    </div>

                </section>
                {/* <!-- Empowering Teams --> */}
                <section class="impowering-section">
                    <div class="text-center">
                        <h2>Empowering<br /> Teams of All Sizes</h2>
                        <p class="para">Whether you’re a global enterprise or a growing startup, Uniteam provides the flexibility and features to fit your needs. Our platform empowers your teams to stay productive and connected.</p>

                    </div>
                    <div class="row my-4">
                        <div class="col-md-12">
                            <img src="../images/about-us/all-size-emp-group.png" class="img-fluid img1" alt="Small teams" />
                        </div>

                    </div>
                </section>

                {/* <!-- Customer First Approach --> */}
                <section class="customer-approach">
                    <div class="row my-4">
                        <div class="col-md-6 col-sm-12">
                            <h2 class="mb-40">Customer First Approach</h2>
                        </div>
                        <div class="col-md-6">
                            <p>At Synthium, we prioritize user experience and customer satisfaction above all else. We actively
                                seek and incorporate feedback from our users to continuously refine and improve our products.
                                Our dedicated support team is committed to providing exceptional service and assistance to
                                ensure that you derive maximum value from our software.</p>

                        </div>
                        <div class="col-md-12 col-sm-12">
                            <img src="../images/about-us/customer-first-approach.png" class="img-fluid img1" alt="Customer handshake" />
                        </div>
                    </div>


                </section>

                {/* <!-- Team Section --> */}
                <section class="team-Section" style={{display: 'none'}}>
                    <div class="text-center">
                        <h2 className='text-center'>Meet The Team</h2>
                        <p className='mb-40'>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
                        <p className='pt-10'> &nbsp;</p>
                    </div>
                    <div class="row my-4">
                        <div class="col-md-4 team-card">
                            <img src="../images/about-us/ceo.svg" class="img-fluid img1" alt="John Doe" />
                            <h4>John Doe</h4>
                            <p className='designation'>CEO & Founder</p>
                        </div>
                        <div class="col-md-4 team-card">
                            <img src="../images/about-us/ceo.svg" class="img-fluid img1" alt="Mary Garcia" />
                            <h4>Mary Garcia</h4>
                            <p className='designation'>Co-Founder</p>
                        </div>
                        <div class="col-md-4 team-card">
                            <img src="../images/about-us/ceo.svg" class="img-fluid img1" alt="Naomi Lamb" />
                            <h4>Naomi Lamb</h4>
                            <p className='designation'>Operations Manager</p>
                        </div>
                    </div>

                </section>

            </div>
            {/* <Footer /> */}
            <NewFooter />
        </div>
    )
}

export default AboutUs