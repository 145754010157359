import React, { useState, useEffect, useCallback } from 'react';
import { Link, useLocation } from 'react-router-dom';

const AdministrativeSettingSidebar = () => {
    const location = useLocation();
    const [loginUserRole, setLoginUserRole] = useState("");
    const [email] = useState(localStorage.getItem("Email"));
    const clientID = Number(localStorage.getItem("ClientID"));
    const ApiBaseURL_url = process.env.REACT_APP_URL;
    const AccessToken = localStorage.getItem("AccessToken");

    // Memoize the API call function to prevent unnecessary re-creations
    const fetchUserDetails = useCallback(async () => {
        try {
            const requestOptions = {
                method: "GET",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${AccessToken}`,
                },
            };
            const response = await fetch(
                `${ApiBaseURL_url}clientusers/profile?viewbyemail=${email}&memberemail=${email}&clientid=${clientID}`,
                requestOptions
            );
            if (response.ok) {
                const result = await response.json();
                setLoginUserRole(result.role);
                //set UserType in the local storage
                localStorage.setItem("UserType", result.role);
                localStorage.setItem("Totalmembers", result.totalMembers);
            } else {
                console.error('Failed to fetch user details');
            }
        } catch (error) {
            console.error("API call failed:", error);
        }
    }, [email, clientID, ApiBaseURL_url, AccessToken]);

    // Only fetch user details if the role is not already set
    useEffect(() => {
        if (!loginUserRole) {
            fetchUserDetails();
        }
    }, [loginUserRole, fetchUserDetails]);

    return (
        <div className="setting-left">
            <div className="setting-nav">
                <ul className="sideways">
                  
                    {/* <li className={location.pathname.includes("/settings/integrations") ? "active" : ""}>
                        <Link to="/settings/integrations">
                            <img alt="" src="/images/setting/integrations.svg" width="" />
                            Integrations
                        </Link>
                    </li> */}
                    {loginUserRole === "Admin" && (
                    <li className={location.pathname === "/settings/storage" ? "active" : ""}>
                        <Link to="/settings/storage">
                            <img alt="" src="/images/setting/database.svg" width="" />
                            Storage
                        </Link>
                    </li>
                    )}
                    {loginUserRole === "Admin" && (
                        <>
                            <li className={location.pathname.includes("/settings/subscription") ? "active" : ""}>
                                <Link to="/settings/subscription">
                                    <img alt="" src="/images/setting/ticket.svg" width="" />
                                    Subscription Details
                                </Link>
                            </li>
                            <li className={location.pathname === "/settings/User" ? "active" : ""}>
                                <Link to="/settings/User">
                                    <img alt="" src="/images/setting/user-gear.svg" width="" />
                                    User Management
                                </Link>
                            </li>
                            <li className={location.pathname.includes("/engagement/managesurvey") || location.pathname.includes("/engagement/happinesssurvey") ? "active submenu" : "submenu"}>
                                <Link to="#">
                                    <img alt="uniteamImage" src="/images/setting/survey-setting-icon.svg" width="" />
                                    Survey Settings
                                </Link>
                                <ul className={location.pathname.includes("/engagement/managesurvey") || location.pathname.includes("/engagement/happinesssurvey") ? "submenu-list-active" : "submenu-list"}>
                                    <li className={location.pathname === "/engagement/managesurvey" ? "active" : ""}>
                                        <Link to="/surveys/engagement">
                                            <span className="emptybadge"></span>Engagement Survey
                                        </Link>
                                    </li>
                                    <li className={location.pathname === "/engagement/happinesssurvey" ? "active" : ""}>
                                        <Link to="/surveys/happiness">
                                            <span className="emptybadge"></span>Happiness Survey
                                        </Link>
                                    </li>
                                </ul>
                            </li>
                        </>
                    )}
                </ul>
            </div>
        </div>
    );
};

export default React.memo(AdministrativeSettingSidebar);

